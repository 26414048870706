/*jshint esversion: 6 */
"use strict";

const tus = require("../modules/tus-pack.js");

var Vue = require("vue/dist/vue");
// import Vue from "vue";

import ivbus from "../utils/ivbus.js";
import env from "../utils/env.js";

import apiClient from "../utils/apiClient.js";

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function setSessionStorageApiToken(token) {
  if (typeof Storage !== "undefined") {
    // Code for localStorage/sessionStorage.
    sessionStorage.apitoken = token;
    localStorage.token = token;
  } else {
    // Sorry! No Web Storage support..
    alert("Please upgrade to a modern browser to log in.");
  }
}

//$(document).ready(function(){

// NAVBAR

// var vm = new Vue({
//   el: "#header",
//   data() {
//     return {
//       userFullName: ""
//     };
//   },
//   mounted: function() {
//     var that = this;
//     ivbus.getFlagSticky("user", function(user) {
//       if (user) {
//         that.userFullName = user.last_name + ", " + user.first_name;
//       } else {
//         that.userFullName = "";
//       }
//     });
//   },
//   methods: {
//     // TODO: replace this with the ivUserAccountMixin user_logout
//     user_logout: function() {
//       sessionStorage.removeItem("apitoken");
//       location = "/login/";
//     }
//   }
// });

// LOGIN

export var ivUserInfoMixin = {
  data() {
    return {
      user: null
    };
  },
  created: function() {
    this.fetchUser();
  },
  methods: {
    fetchUser: function() {
      var that = this;
      // redirect to login if we're invalid, and setFlag('userFullName') if we're valid
      if (typeof sessionStorage.apitoken === "undefined") {
          console.error("Apitoken is undefined")
        if (typeof loginRequired !== "undefined" && loginRequired === true) {
            console.error("Login required")
          var params = window.location.search;
          window.location =
            "/login/?r=" +
            window.location.pathname +
            (params ? "&" + params.slice(1) : "") +
            window.location.hash;
        }
        Vue.set(that, "user", null);
      } else {
          console.log("Apitoken is Defined")
        var spinId = that.spinStart();
        // Vue.http.headers.common.Authorization =
        //   "Token " + sessionStorage.apitoken;
        apiClient.defaults.headers["Authorization"] = "Token " + sessionStorage.apitoken;
        apiClient
          .get("/api/user/user_info/")
          .then(function(response) {
              console.log("Got a response for user_info", response)
            that.spinStop(spinId);
            if (response.data.status === false) {
              console.error("Got a response status false")
              if (loginRequired) {
                  console.log("Login required")
                var params = window.location.search;
                window.location =
                  "/login/?r=" +
                  window.location.pathname +
                  (params ? "&" + params.slice(1) : "") +
                  window.location.hash;
              }
              Vue.set(that, "user", null);
            } else {
              // if the hostname of r == hostname of our page, stay here, otherwise redirect to r.
              if (response.data.r !== true) {
                // hostname https://stackoverflow.com/questions/27745/getting-parts-of-a-url-regex/26766402#26766402
                if (
                  response.data.r.match(
                    /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/
                  )[4] !== window.location.hostname
                ) {
                    console.error("setting window location")
                  window.location = response.data.r;
                }
              }
              if (response.data.force_redirect) {
                window.location = response.data.force_redirect;
              }
              Vue.set(that, "user", response.data);
              ivbus.setFlag("user", response.data);
            }
          })
          .catch(function(err) {
              console.error("user_info failed", err)
            that.spinStop(spinId);
          });
      }
    }
  }
};

// MODAL

export var ivModalMixin = {
  mounted: function() {
    window.setTimeout(function() {
      $(".modal-content").css("max-height", $(window).height() - 60);
    });
  },
  methods: {
    closeModal: function(modal) {
      $("#" + modal).modal("hide");
    },

    openModal: function(modal) {
      $("#" + modal).modal("show");
    }
  }
};

// This feels really fragile. But I think it works. So we pass an object into ivSpinStart, which (possibly asynchronously) gets spinnerId populated.
// Return the object to the client, and then pass it again for spinStop. Because iv-spinner calls onQ for Start before Stop, the spinnerId will,
// I believe, always be populated by the time spinStop is actually executed. So as long as we keep hands off of the spinconfig object, it should all work!
// TODO: I think it's time to let VueX handle state mutation for us.

// ALERTS

export var ivAlertMixin = {
  data: function() {
    return {
      uiMessageWaitLong: 10000,
      uiMessageWaitShort: 5000
    };
  },
  created: function() {
    this.alerts = {}; // global bucket for alert references
  },
  methods: {
    showMessage: function(text, classes, duration) {
      var message = {
        classes: classes,
        text: text,
        timeout: duration
      };
      ivbus.emitQ("ivAlertShow", message);
      return message; // message will now be decorated with an alertId
    },

    hideMessage: function(message) {
      ivbus.emitQ("ivAlertHide", message);
    }
  }
};

// SPINNER

export var ivSpinnerMixin = {
  created: function() {
    this.spinners = {}; // global bucket for spinner references
  },
  methods: {
    spinStart: function(config) {
      var spinconfig = {
        metadata: config ? config.metadata : null,
        spinkey: config ? config.spinkey : null
      };
      ivbus.emitQ("ivSpinStart", spinconfig);
      return spinconfig; // spinconfig is now docorated with spinnerId
    },
    spinStop: function(spinconfig) {
      ivbus.emitQ("ivSpinStop", spinconfig);
    }
  }
};

// USER ACCOUNT

export var ivUserAccountMixin = {
  methods: {
    user_logout: function() {
      var that = this;
      var spinId = that.spinStart();
      apiClient
        .get(env.serverAddr + "/api/user/sign_out/")
        .then(function(response) {
          that.spinStop(spinId);
          sessionStorage.removeItem("apitoken");
          // delete Vue.http.headers.common.Authorization;
          ivbus.setFlag("user", null);
        })
        .catch(function() {
          that.spinStop(spinId);
          sessionStorage.removeItem("apitoken");
          // delete Vue.http.headers.common.Authorization;
          ivbus.setFlag("user", null);
        });
    },

    user_login: function() {
      var that = this;
      var messageId = that.showMessage(
        "Logging in...",
        "alert-warning",
        that.uiMessageWaitLong
      );

      var spinId = that.spinStart();
      apiClient
        .post(env.serverAddr + "/api/user/api-token-auth/", {
          username: that.loginemail,
          password: that.loginpassword
        })
        .then(function(response) {
          that.spinStop(spinId);
          that.hideMessage(messageId);
          if (response.data.status === false) {
            that.showMessage(
              "Invalid email or password.",
              "alert-danger",
              that.uiMessageWaitLong
            );
          } else {
            if (response.data.token !== undefined) {
              setSessionStorageApiToken(response.data.token);
              that.fetchUser();
            } else {
              that.user_logout();
            }
          }
        })
        .catch(function(response) {
          that.hideMessage(messageId);
          that.showMessage(
            "Invalid email or password.",
            "alert-danger",
            that.uiMessageWaitLong
          );
          that.spinStop(spinId);
          that.user_logout();
        });
    },

    user_forgot_password: function() {
      window.location.href = "https://initialview.com/password/forgot";
    }
  }
};

// DRAG UPLOAD
//import("../utils/tus/tus.js");
//const tus = require("../utils/tus/tus.js");

export var ivChunkUploadMixin = {
  created: function() {
    console.log("chunkUpload mixin created");
  },
  methods: {
    chunkUpload: function(
      endpoint,
      file,
      context,
      progressBar,
      callback,
      errorCb
    ) {
      console.log("inside chunkUpload");
      var that = this;
      Vue.set(context, progressBar, true);

      //const tus = require("../utils/tus/tus.js");
      console.log(tus);

      var upload = new tus.Upload(file, {
        endpoint: env.serverAddr + endpoint,
        metadata: {
          token: sessionStorage.apitoken,
          filename: file.name
        },
        retryDelays: [0, 1000, 3000, 5000],
        chunkSize: 1000 * 1024, // 1MB
        onError: function(error) {
          typeof errorCb === "function" ? errorCb(error) : "";
        },
        onProgress: function(bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          // console.log("progress: ", bytesUploaded, bytesTotal, percentage + "%");
          // Vue.set(that, progressBar, percentage);
        },
        onChunkComplete: function(chunkSize, bytesAccepted, bytesTotal) {
          var percentage = ((bytesAccepted / bytesTotal) * 100).toFixed(0);
          Vue.set(context, progressBar, percentage);
        },
        onSuccess: function() {
          var splitted = upload.url.split("/");
          var resource_id = splitted[splitted.length - 1];
          callback(resource_id);
        }
      });
      upload.start();
    }
  }
};

// PLAYER MIXIN

export var ivPlayerMixin = {
  mixins: [ivModalMixin, ivSpinnerMixin],

  data: function() {
    return {
      current_tab: "video",

      urlAddVparam: "",

      videometa: {
        status: 0,
        star: false,
        recruited: false,
        ivVideo: "https://initialview.com/ivgcs/video/blank.mp4",
        ivVideoData: {
          questions: []
        },
        user: "",
        org: "",
        message: "Loading...",
        date: "",
        ivType: "",
        block_video: false
      },

      ivWriting: "",
      ivSummary: "",
      ivResume: "",
      ivHSProfile: '',
      starreport: {},

      currentVideoQuestionId: 0
    };
  },
  created: function() {
    ivbus.$on("iv-player-current-question", this.setCurrentVideoQuestionId);
    ivbus.$on("iv-player-mounted", this.fetchVideoMetadata);

    var vparam = getParameterByName("v");
    this.urlAddVparam = "";
    if (vparam !== null) {
      this.urlAddVparam = "?v=" + vparam;
    }
  },

  methods: {
    fetchVideoMetadata: function() {
      var that = this;

      //get param string based on credentials
      var urlParamString = "";

      urlParamString = that.urlAddVparam;

      if (urlParamString == "") {
        that.fetchUser();
      }

      var spinId = that.spinStart();
      apiClient
        .get(env.serverAddr + "/api/interview/video_info/" + urlParamString)
        .then(function(response) {
          that.spinStop(spinId);
          if (response.data.status == 200) {            
            that.videometa = response.data;
            that.videoMetaReady();
          } else if (response.data.status == 403) {
            ivbus.$emit("login-required", true);
          } else {
            that.displayNotFound();
          }
        })
        .catch(function(error) {
          that.spinStop(spinId);
        });
    },

    videoMetaReady: function() {
      var that = this;
      ivbus.$emit("meta-ready", that.videometa);
      window.setTimeout(function() {
        $("#questions").height(
          $("#interview_questions_wrapper").height() - 100
        );
        $("#iver-feedback").offset($("#feedback-anchor").offset());
      });
    },

    showTab: function(tab) {
      this.current_tab = tab;
    },

    selectQuestion: function(q) {
      ivbus.$emit("iv-player-timetravel", q.time);
      this.setCurrentVideoQuestionId(q.index); // q.index and currentVideoQuestionId are both 1-based
    },

    setCurrentVideoQuestionId: function(i) {
      this.currentVideoQuestionId = i; // i+1 if event comes from iv-player?
    },

    fetchWritingInfo: function() {
      var that = this;
      var spinId = that.spinStart();
      apiClient
        .get(
          env.serverAddr + "/api/interview/writing_info/" + that.urlAddVparam
        )
        .then(function(response) {
          that.spinStop(spinId);
          if (response.data.status == 200) {
            that.ivWriting = response.data.url;
          } else {
            that.ivWriting = "";
          }
        })
        .catch(function(error) {
          console.log(error);
          that.spinStop(spinId);
        });
    }
  }
};

// USER DIAGNOSTICS

export const ivDiagnosticsMixin = {
  data() {
    return {
      currentSession: {},
      sessionSendTimer: {}
    };
  },
  created: function() {
    this.resetSession();

    this.sessionAppend({
      // this only needs to be done once per service instantiation
      action: "DEVICE_INFO",
      platform: "browser",
      version: "base-components-pack"
    });

    this.sessionSendTimer = window.setTimeout(() => {
      this.sessionSend();
    }, 60 * 1000);

    window.addEventListener("unload", function() {
      // TODO: beacon for diagnostics
      // navigator.sendBeacon("/shield/collaboration/beaconevent/", new Blob([$.param({auth: sessionStorage.apitoken, message_state: "closed", message_bids: [bid]})], {type: "application/x-www-form-urlencoded"}));
    });
  },
  methods: {
    resetSession: function() {
      var that = this;
      Vue.set(this, "currentSession", {
        user: null,
        data: []
      });

      ivbus.getFlagSticky("user", function(user) {
        that.currentSession.user = user.email;
      });
    },

    sessionAppend: function(record) {
      record.timestamp = new Date().toISOString();
      this.currentSession.data.push(record);
    },

    sessionSend: function() {
      window.clearTimeout(this.sessionSendTimer); // incase it was invoked manually
      if (this.currentSession.data.length > 0) {
        let sendingSession = JSON.parse(JSON.stringify(this.currentSession));
        this.resetSession();
        apiClient.post(
          env.serverAddr + "/api/app/session/info/",
          sendingSession
        );
      }
      this.sessionSendTimer = window.setTimeout(() => {
        this.sessionSend();
      }, 60 * 1000);
    }
  }
};

//});
